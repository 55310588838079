<template>
  <vx-card class="user-profile-page main-box top-zero-radius md:w-1/2"
           v-if="Object.keys(user).length > 0">

    <profile-main-box :profile="getUserProfile"
                      :actions-list="actionsList"
                      @showUserInvoices="showProfileInvoicesPromptStatus = true"/>


    <!-- show user invoices prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showProfileInvoicesPromptStatus"
      @close="showProfileInvoicesPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <!--            <div @click="editUserPromptStatus = true">
                          <custom-icon icon="EDIT" color="warning"/>
                        </div>-->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('profile.invoices.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showProfileInvoicesPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <profile-invoices-list/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <!-- insert new product prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showEditProfilePromptStatus"
      @close="showEditProfilePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('EditProfileBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('profile.edit.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showEditProfilePromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <edit-profile @edited="showEditProfilePromptStatus = false, getProfile()"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>


    <!--  show coach classes list prompt  -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showCoachClassesListPromptStatus"
      @close="showCoachClassesListPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('coaches.classes.dynamicTitle', {name: getUserProfile.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showCoachClassesListPromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <coach-classes-list/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <vs-button id="editProfileBTN" class="useral-action-button" @click="showEditProfilePromptStatus = true"/>
  </vx-card>
</template>

<script>
  import axios from 'axios'
  import ProfileMainBox from '@/components/profileMainBox/profileMainBox2'
  import {getProfile} from '@/http/requests/users/users'
  import {getAvatarUrl} from '@/assets/js/functions'
  import EditProfile from "../edit/editProfile";
  import CustomIcon from "../../../../components/customIcon/customIcon";
  import ProfileInvoicesList from "../invoices/profileInvoices";
  import {getStaticFileUrl} from "../../../../assets/js/functions";
  import CoachClassesList from "../classes/coachClassesList";

  export default {
    name: 'profile',
    components: {CoachClassesList, ProfileInvoicesList, CustomIcon, EditProfile, ProfileMainBox},
    data() {
      return {
        showEditProfilePromptStatus: false,
        showProfileInvoicesPromptStatus: false,
        showCoachClassesListPromptStatus: false,
        actions: {
          toolbar: [
            {
              id: 'editProfileBTN',
              icon: 'EDIT',
              iconPack: 'useral',
              color: 'warning'
            }
          ]
        },
        user: {},
        actionsList: [
          {
            event: 'showUserInvoices',
            header: '',
            // preview: {
            //   value: addComma(25000) + ' تومان',
            //   color: 'success'
            // },
            i18n: 'profile.actions.invoices',
            icon: 'INVOICE',
            colorCode: '#000',
            iconPack: 'useral'
          },
          {
            name: 'profileReceivedEvents',
            header: '',
            i18n: 'profile.actions.receivedEvents',
            icon: 'icon-message-circle',
            iconPack: 'feather'
          },
          {
            name: 'profileSentEvents',
            header: '',
            i18n: 'profile.actions.sentEvents',
            icon: 'icon-send',
            iconPack: 'feather',
            permission: 'event.create'
          }
        ]
      }
    },
    created() {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
      }, 50)

      this.getProfile()
    },
    computed: {
      getUserProfile() {
        const user = {
          avatar: this.user.avatar ? getAvatarUrl(this.user.avatar) : this.user.general.gender === 1 ? getStaticFileUrl('/images/man-avatar.png') : getStaticFileUrl('/images/woman-avatar.png'),
          name: `${this.user.general.name.value} ${this.user.general.lastName.value}`,
          general: this.user.general,
          asiderAvatar: [
            {
              content: `${this.user.general.name.value} ${this.user.general.lastName.value}`,
              preContent: this.user.general.gender === 1 ? this.$t('genderTypes.dynamicMan') : this.user.general.gender === 2 ? this.$t('genderTypes.woman') : ''
            },
            {
              content: this.user.general.phoneNumber.value,
              preContent: this.$t('profile.asiderAvatar.phoneNumber')
            },
            {
              content: this.user.character ? this.user.character.name : '',
              preContent: this.$t('profile.asiderAvatar.character')
            },
            {
              content: this.user.access ? this.user.access.name : '',
              preContent: this.$t('profile.asiderAvatar.access')
            }
          ],
          details: [],
          importantData: []
        }

        return user
      }
    },
    methods: {
      getProfile() {
        getProfile().then(response => {
          const user = response.data.data
          this.user = {
            id: user.id,
            avatar: user.avatar || '',
            general: {
              name: {
                value: user.name,
                isInvalid: ''
              },
              companyName: {
                value: '',
                isInvalid: ''
              },
              lastName: {
                value: user.family,
                isInvalid: ''
              },
              gender: user.gender,
              email: {
                value: user.email ? user.email : '',
                isInvalid: ''
              },
              phoneNumber: {
                value: user.phone_number,
                isInvalid: ''
              }
            },
            coachId: user.coach_id,
            character: user.character,
            access: user.role,
            group: user.group,
            financial: {
              maxDebt: user.maxDebt ? user.maxDebt : 0,
              balance: user.balance ? user.balance : 0
            }
          }

          if (user.is_coach) {
            let actionsList = JSON.parse(JSON.stringify(this.actionsList))
            const coachClasses = {
              event: 'coachClasses',
              i18n: 'users.user.profile.actions.coachClasses',
              icon: 'CALENDER',
              colorCode: '#000',
              iconPack: 'useral',
            }

            actionsList.splice(0, 0, coachClasses)
            this.actionsList = []
            setTimeout(() => {
              this.actionsList = actionsList
            }, 50)
          }

          this.$store.dispatch('setPageTitle', this.$t('profile.dynamicTitle', {name: `${this.user.general.name.value} ${this.user.general.lastName.value}`}))
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
      },
      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss">
  .user-profile-page {
    padding-top: 20px;

    .vx-card__body {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
</style>
